.service {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .table {
        width: 100%;
        border: 1px solid gray !important;
        border-spacing: 0;
        th {
            background-color: rgba(128, 128, 128, 0.396);
            padding: 5px 0;
        }

        td {
            text-align: center;

            .action {
            padding: 5px 0;
                display: flex;
                justify-content: space-evenly;

            }
        }
    }
}


.modalServiceBody {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 30vw;
    .modalServiceBodyNumber{
        display: flex;
        flex-direction: row;
        gap: 10px;
        input{
            width: 100%;
        }
    }
}