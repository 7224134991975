.passwordSettings {
    display: flex;
    gap: 10px
}

.btn {
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 25px;
}