@import "../../../../styles/variables.scss";

.lawyerList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    .lawyer {
        display: flex;
        max-width: 150px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .photoCard {
            height: 100px;
            width: 100px;
            border-radius: 100px;
            background-color: $blue-site;
            border: 2px solid $orange;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            color: transparent;

            .photo {
                border-radius: 100px;
                height: 100%;
                width: 100%;
            }
        }
    }
}