    .card {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        display: flex;
        padding: 15px;
        gap: 20px;

            .title {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-items: start;
                span{
                    font-size: 20px;
                }

                                .formHoliday{
                                    display: flex;
                                    width: 250px;
                                    gap: 20px;
                                }
            }
            .formAvailabilities {
            width: 100%;
                display: flex;
                justify-content: space-between;
            }

        
        .btnAvailabilitiesSave {
            display: flex;
            width: 100%;
            justify-content: end;
        }
    }