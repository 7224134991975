.label {
    font-size: 20px;
    font-weight: bold;
}

.address {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;

    .streetName {
        width: 100%;
    }

    .zipCode {
        width: 15%;
    }

    .city {
        width: 20%;
    }

    .country {
        width: 20%;
    }
}