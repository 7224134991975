@import "../../../../../../styles/variables.scss";

.container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 15px 0 0 0;
    gap: 20px;

    //    overflow-y: auto;
    //    height: 65vh;
    .elementContainer {
        width: 100%;
    }

    .removeElement {
        display: none;
    }

    .elementContainer:hover {
        background-color: #607d8b1c;

        .removeElement {
            display: initial;
            position: absolute;
            right: 25%;
            color: red;
        }
    }

}

.addValueContainer {
    display: flex;
    align-items: end;
}

.actionElementContainer {
    display: flex;
}

.inputContainer {
    width: 100%;
    margin-top: 10px;
    display: flex;
    align-items: end;
    gap: 10px;
}

.valuesContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.valueContainer {
    display: flex;
    text-align: center;
    align-items: center;
    background: $blue-site;
    color: white;
    border-radius: 7.5px;
    gap: 10px;
}

.actionContainer {
    padding: 20px 5%;
    background-color: #607d8b1c;
    border-radius: 5px;
}

.buttonContainer {
    margin: 30px 0 0 0;
}