@import "../../styles/variables.scss";

.progressContainer {
    display: flex;
    overflow-x: auto;
    max-width: 100%;

    &::-webkit-scrollbar-thumb {
        display: none;

        :hover>& {
            display: initial;
        }
    }

    .progressBar {
        display: flex;
        align-items: center;
        width: 100%;
        min-width: 200px;
        height: 150px;

        .dot {
            position: sticky;
            left: 0;
            height: 20px;
            width: 20px;
            border-radius: 10px;
            background-color: $blue-site;
            margin: 0 5px;
        }

        .step {
            position: relative;


            .label {
                position: absolute;
                width: 100px;
                top: -60px;
            }

            .date {
                position: absolute;
                top: 25px;
                font-size: 10px;
            }
        }

        .bar {
            height: 10px;
            width: 100%;
            border-radius: 5px;
            background-color: $orange;
        }
    }
}