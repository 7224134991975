@import "../../../../../styles/variables.scss";

.body {
    padding: 0 25px;

    .editor {
        background-color: $blue-site-transparent;
        border-radius: 10px;
        padding: 10px;
        height: calc(100vh - 255px);

        .titleAction {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

    }
}