.container {

    .meetings {
        display: flex;
        gap: 20px;
        width: 100%;
        margin-top: 30px;

        .calendar {
            width: 30%;

            h2 {
                font-size: 20px;
            }

            .legends {
                display: flex;
                padding: 10px 0;
                gap: 10px;

                // .legendAllow {
                //     height: 20px;
                //     width: 20px;
                //     border-radius: 5px;
                //     border: 1px solid green;
                //     background-color: rgba(142, 223, 129, 0.5);
                // }

                .legendNow {
                    height: 20px;
                    width: 20px;
                    border-radius: 5px;
                    border: 1px solid #fee574;
                    background-color: #fffae3;
                }

                .legendUnallow {
                    height: 20px;
                    width: 20px;
                    border-radius: 5px;
                    border: 1px solid red;
                    background-color: #ff000080;
                }
            }
        }

        .metingTab {
            width: 70%;
        }
    }


}