@import "../../styles/variables.scss";

.formResponseContainer {
    display: flex;
    flex-direction: column;
    background-color: $blue-site-transparent;
    overflow-y: scroll;
    border-radius: 10px;
    width: calc(100% - 50px);
    padding: 25px;
    height: calc(100% - 65px);

    .formResponse {
        border: none;

        td {
            padding: 10px;
            vertical-align: top;
            text-align: start;
        }

        .values {
            display: flex;
            flex-direction: column;
        }
    }
}