@import "../../styles/variables.scss";

.tabsContainer {
    display: flex;
    flex-wrap: wrap;

    .tab {
        width: 100%;
        display: flex;
        flex: row;
        overflow-x: auto;
        border-bottom: 1px solid #e6e6e6;

        .btn {
            font-size: 15px;
            border: 0;
            background: #ffffff00;
            color: #808080;
            padding: 10px;
        }

        .active {
            padding: 10px;
            font-size: 15px;
            font-weight: bolder;
            background: #ffffff00;
            color: $blue-site;
            background-color: $orange-site-light;
            border: 0;
            border-radius: 5px 5px 0 0;
            border-bottom: 2px solid $orange;
        }
    }

    .content {
        width: 100%;
    }
}