@import "../../../../styles/variables.scss";

.body {
    padding: 0 25px;

    .description {
        background-color: $blue-site-transparent;
        border-radius: 10px;
        padding: 10px;
        margin: 20px 0;
        max-width: 69vw;

        h2 {
            margin-top: 10px;
        }

        .titleAction {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

    }

    .modalStep {
        display: flex;
        flex-direction: column;
    }

    .eventModalBody {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin: 20px 0;

        div {
            width: 100%;
        }
    }

    .eventModalAction {
        display: flex;
        justify-content: space-between;
    }
}