@import "../../../../styles/variables.scss";

.container {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;

    .btns {
        display: flex;
        justify-content: end;
        width: 100%;
        margin-bottom: 10px;
    }

    .table {
        width: 100%;
    }

    .iconCol {
        width: 50px;
    }

    .status {
        padding: 2.5px;
        border-radius: 5px;
    }

    .actionBtn {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}