@import "../../../../../styles/variables.scss";


.infoContainer {
    background-color: $blue-site-transparent;
    border-radius: 10px;
    padding: 10px;
    margin: 20px 0;
    max-width: 69vw;

    h2 {
        margin-top: 10px;
    }

    .titleAction {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 30px;
    }

    .personnalData {
        display: flex;
        flex-direction: column;
        font-size: 17px;
        gap: 3px;

        .fullname {
            font-size: 20px;
            font-weight: bolder;
            margin-bottom: 5px;
        }
    }

}