@import "../../styles/variables.scss";

.divInput {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.labelInput {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
    color: $gray-700;
    font-weight: 500;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    .input {
        max-width: 100%;
        padding: 0.5rem 1rem;
        border: 1px solid $gray-300;
        border-radius: $radius;
        font-size: 1rem;

        &:focus {
            outline: none;
            text-decoration: none;
            border-color: $orange;
            box-shadow: 0 0 0 2px rgba(249, 115, 22, 0.2);
        }
    }

    .loading {
        position: absolute;
        top: 45px;
        right: 20px;

        span {
            font-size: 3px;
        }
    }
}


.list {
    position: relative;

    ul {
        position: absolute;
        list-style-type: none;
        background-color: #ffffff;
        border-radius: 0 0 5px 5px;
        box-shadow: 5px 5px 10px grey;
        padding: 0;
        top: -15px;
        width: 100%;
        max-height: 150px;
        z-index: 3;
        overflow: hidden;
        overflow-y: auto;

        li {
            &:hover {
                background-color: $orange-site-transparent;
            }

            button {
                text-align: left;
                width: 100%;
                padding: 10px;
            }
        }
    }
}