// Colors
$orange: #f97316;
$orange-light: #fff7ed;
$orange-dark: #ea580c;
$blue: #1e52a4;
$blue-light: #eff6ff;
$gray-50: #f9fafb;
$gray-100: #f3f4f6;
$gray-200: #e5e7eb;
$gray-300: #d1d5db;
$gray-400: #9ca3af;
$gray-500: #6b7280;
$gray-600: #4b5563;
$gray-700: #374151;
$gray-800: #1f2937;
$gray-900: #111827;
$white: #ffffff;
$black: #000000;

$orange-site: #e78932;
$blue-site: #1e52a4;
$orange-site-transparent: #e789327f;
$blue-site-transparent: #20468308;
$blue-site-light: #c4d0e7;
$orange-site-light: #fcebde;
$bluegray-site: #405b90;
$case-color: #ffbc20;

// Shadows
$shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
$shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
$shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);

// Border radius
$radius: 0.5rem;

// Transitions
$transition: all 0.2s ease;

// Breakpoints
$breakpoint-sm: 640px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;