@import "../../../../../styles/variables.scss";


.infoContainer {
    background-color: $blue-site-transparent;
    border-radius: 10px;
    padding: 10px;
    margin: 20px 0;
    max-width: 69vw;

    h2 {
        margin-top: 10px;
    }

    .titleAction {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 30px;

        .action {
            display: flex;
            gap: 5px;
        }
    }

    .noteContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .noteTabs {
            max-width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-y: auto;
            background-color: $blue-site;
        }

        ::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, 0.202);
        }

        ::-webkit-scrollbar-thumb:hover {
            background: rgba(255, 255, 255, 0.609);
        }
    }

}