@import "../../../styles/variables.scss";

.cardContainer {
    width: 400px;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 5px 5px 10px grey;
    margin: 15px;
    border-radius: 5px;

    .coverCard {
        width: 100%;
        height: 100px;
        background: linear-gradient(to right, $blue-site, $orange);
        border-radius: 5px 5px 0 0;

        .photoContainer {
            position: relative;
            width: 100%;

            .photoCard {
                position: absolute;
                top: 50px;
                left: 20px;
                height: 100px;
                width: 100px;
                border-radius: 50px;
                background-color: $blue-site;
                border: 2px solid $orange;
                background-size: cover;
                background-repeat: repeat;
                background-position: center;
                color: transparent;
            }
        }
    }



    .cardBody {
        width: 100%;
        display: flex;
        flex-direction: column;

        .cardBodyId {
            padding: 10px 0 10px 130px;
            font-size: 20px;
            font-weight: bold;
        }

        .underlined {
            text-decoration: underline 3px solid $orange;
            text-underline-position: under;
        }

        .cardSpe {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            padding: 30px 10px 10px 10px;
            font-size: 8px;
        }

        .cardBodyInfo {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 10px;

            span {
                display: flex;
                gap: 10px;
            }
        }
    }

    .cardFooter {
        width: 100%;
        height: 30px;
        position: relative;
        padding: 5px 10px;
        display: flex;
        justify-content: end;
        align-self: flex-end;
    }
}

.searchedSpeciality {
    border: 2px solid $orange;
}