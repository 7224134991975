@import "../../styles/variables.scss";

.title {
    text-align: center;
}

.container {
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 50px;

    h1,
    h2 {
        width: 100%;
        text-align: center;
        text-decoration: underline 3px solid $orange;
        text-underline-position: under;
    }
}