// Variables
$orange: #f97316;
$orange-light: #fff7ed;
$orange-dark: #ea580c;
$blue: #2563eb;
$blue-light: #eff6ff;
$blue-dark: #1d4ed8;
$gray-50: #f9fafb;
$gray-100: #f3f4f6;
$gray-200: #e5e7eb;
$gray-300: #d1d5db;
$gray-400: #9ca3af;
$gray-500: #6b7280;
$gray-600: #4b5563;
$gray-700: #374151;
$gray-800: #1f2937;
$gray-900: #111827;
$white: #ffffff;
$black: #000000;
$facebook: #1877f2;
$twitter: #1da1f2;
$linkedin: #0a66c2;
$instagram: #e4405f;
$shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
$shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
$shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
$radius: 0.5rem;
$transition: all 0.2s ease;

// Breakpoints
$breakpoint-sm: 640px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;

// Mixins
@mixin respond-to($breakpoint) {
    @if $breakpoint =="sm" {
        @media (min-width: $breakpoint-sm) {
            @content;
        }
    }

    @else if $breakpoint =="md" {
        @media (min-width: $breakpoint-md) {
            @content;
        }
    }

    @else if $breakpoint =="lg" {
        @media (min-width: $breakpoint-lg) {
            @content;
        }
    }

    @else if $breakpoint =="xl" {
        @media (min-width: $breakpoint-xl) {
            @content;
        }
    }
}

// Base styles
.container {
    width: 100%;
    min-height: 100vh;
    background-color: $gray-50;
}

// Hero Section
.hero {
    background-color: $blue;
    color: $white;
    padding: 5rem 0;
    text-align: center;
}

.heroContent {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1rem;
}

.heroTitle {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;

    @include respond-to("md") {
        font-size: 3rem;
    }
}

.heroSubtitle {
    font-size: 1.25rem;
    max-width: 700px;
    margin: 0 auto;

    @include respond-to("md") {
        font-size: 1.5rem;
    }
}

// Section Layout
.section {
    padding: 4rem 0;
}

.sectionContent {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.altBg {
    background-color: $white;
}

.sectionTitle {
    font-size: 1.875rem;
    font-weight: 700;
    margin-bottom: 2rem;
    color: $gray-800;

    @include respond-to("md") {
        font-size: 2.25rem;
    }
}

// Contact Grid
.contactGrid {
    display: grid;
    gap: 3rem;

    @include respond-to("md") {
        grid-template-columns: repeat(2, 1fr);
    }
}

// Contact Info
.contactInfo {
    .sectionTitle {
        text-align: left;
    }
}

.infoItems {
    margin-bottom: 2.5rem;
}

.infoItem {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.5rem;
}

.infoIcon {
    width: 3rem;
    height: 3rem;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    flex-shrink: 0;
    font-size: 1.25rem;
}

.blueIcon {
    background-color: $blue-light;
    color: $blue;
}

.orangeIcon {
    background-color: $orange-light;
    color: $orange;
}

.infoContent {
    flex-grow: 1;
}

.infoTitle {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
    color: $gray-800;
}

.infoText {
    color: $gray-600;
    margin: 0;
}

// Social Media
.socialContainer {
    margin-top: 2.5rem;
}

.socialTitle {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: $gray-800;
}

.socialIcons {
    display: flex;
    gap: 1rem;
}

.socialIcon {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    transition: $transition;

    &:hover {
        transform: translateY(-3px);
    }
}

.facebook {
    background-color: $facebook;

    &:hover {
        background-color: darken($facebook, 10%);
    }
}

.twitter {
    background-color: $twitter;

    &:hover {
        background-color: darken($twitter, 10%);
    }
}

.linkedin {
    background-color: $linkedin;

    &:hover {
        background-color: darken($linkedin, 10%);
    }
}

.instagram {
    background-color: $instagram;

    &:hover {
        background-color: darken($instagram, 10%);
    }
}

// Contact Form
.formCard {
    background-color: $white;
    padding: 2rem;
    border-radius: $radius;
    box-shadow: $shadow-md;
}

.formTitle {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: $gray-800;
}

.formRow {
    display: grid;
    gap: 1rem;
    margin-bottom: 1rem;

    @include respond-to("md") {
        grid-template-columns: repeat(2, 1fr);
    }
}

.formGroup {
    margin-bottom: 1rem;
}

.formLabel {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
    color: $gray-700;
}

.inputWithIcon {
    position: relative;
}

.inputIcon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: $gray-400;
}

.formControl {
    width: 100%;
    padding: 0.75rem 1rem 0.75rem 2.5rem;
    border: 1px solid $gray-300;
    border-radius: $radius;
    font-size: 1rem;
    transition: $transition;

    &:focus {
        outline: none;
        border-color: $blue;
        box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.2);
    }

    &::placeholder {
        color: $gray-400;
    }
}

textarea.formControl {
    padding-left: 1rem;
}

.formCheckbox {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.5rem;
}

.checkbox {
    margin-top: 0.25rem;
    margin-right: 0.75rem;
    width: 1rem;
    height: 1rem;
}

.checkboxLabel {
    font-size: 0.875rem;
    color: $gray-700;
}

.link {
    color: $blue;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.submitButton {
    width: 100%;
    background-color: $orange;
    color: $white;
    padding: 0.75rem 1rem;
    border: none;
    border-radius: $radius;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: $transition;

    &:hover {
        background-color: $orange-dark;
    }
}

.buttonIcon {
    margin-right: 0.5rem;
}

// Map
.mapContainer {
    height: 24rem;
    border-radius: $radius;
    overflow: hidden;
    box-shadow: $shadow-md;
}

.mapPlaceholder {
    width: 100%;
    height: 100%;
    background-color: $gray-300;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-600;
}

// FAQ
.faqContainer {
    max-width: 800px;
    margin: 0 auto;
}

.faqItem {
    background-color: $white;
    padding: 1.5rem;
    border-radius: $radius;
    box-shadow: $shadow-md;
    margin-bottom: 1.5rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.faqQuestion {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
    color: $gray-800;
}

.faqAnswer {
    color: $gray-700;
    line-height: 1.6;
}

// CTA Section
.ctaSection {
    background-color: $blue;
    color: $white;
    padding: 4rem 0;
    text-align: center;
}

.ctaTitle {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;

    @include respond-to("md") {
        font-size: 2.25rem;
    }
}

.ctaText {
    font-size: 1.25rem;
    max-width: 700px;
    margin: 0 auto 2rem;
}

.ctaButtons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;

    @include respond-to("sm") {
        flex-direction: row;
    }
}

.btnWhite {
    background-color: $white;
    color: $blue;
    padding: 0.75rem 2rem;
    border-radius: $radius;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: $transition;

    &:hover {
        background-color: $gray-100;
    }
}

.btnOrange {
    background-color: $orange;
    color: $white;
    padding: 0.75rem 2rem;
    border-radius: $radius;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: $transition;

    &:hover {
        background-color: $orange-dark;
    }
}