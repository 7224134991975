@import "../../../styles/variables.scss";

.file {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 5px;
    font-size: 50px;
    width: 60px;
    height: 60px;
}

.filename {
    position: absolute;
    top: 50px;
    font-size: 12px;
    text-overflow: ellipsis;
}

.fa-file-archive {
    color: #949494;
}

.fa-file-audio {
    color: green;
}

.fa-file-code {
    color: blue;
}

.fa-file-excel {
    color: #195b37;
}

.fa-file-image {
    color: #32c2d2;
}

.fa-file-video {
    color: #f78400;
}

.fa-file-pdf {
    color: red;
}

.fa-file-powerpoint {
    color: #c73b1a;
}

.fa-file-word {
    color: #153d92;
}

.fa-file {
    color: #000000;
}

.fa-file-text {
    color: #555555;
}

.fa-folder {
    color: $case-color;
}

.fa-folder-open {
    color: $case-color;
}