.formEditor {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    min-height: 100vh;

    .actionContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 20px;
        margin-top: 20px;

        .formActionContainer {
            display: flex;
            flex-direction: column;
            height: 100%;
            position: sticky;
            top: 20px;
            z-index: 5;
            background: #607d8b1c;
            border-radius: 10px;
            padding: 20px;


            .dataActionBtn {
                margin-top: 100px;
                justify-self: end;
                display: flex;
                flex-direction: column;
                gap: 20px;


                .btn {
                    text-align: center;
                    display: flex;
                    justify-content: center;
                }

                .delete {
                    color: red;
                    border: 2px solid red;
                    background: none;
                }
            }
        }
    }


    .formContainer {
        display: flex;
        flex-direction: row;
        gap: 30px;

        .formDataContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            background: white;
            border-radius: 10px;
        }
    }
}