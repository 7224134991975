@import "../../styles/variables";

.divInput {
    display: flex;
    flex-direction: column;
}

.labelInput {
    display: flex;
    align-items: center;
}

.input {
    max-width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid $gray-300;
    border-radius: $radius;
    font-size: 1rem;

    &:focus {
        outline: none;
        text-decoration: none;
        border-bottom: 1px solid #0091ff;
    }

    &:disabled {
        background: #dcdcdc;
    }
}