.modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(128, 128, 128, 0.5);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;

    .card {
        padding: 20px;
        position: relative;

        .btnClose {
            position: absolute;
            right: 0;
            top: 0;
        }

        .title {
            font-size: 25px;
        }

        .body {
            margin-top: 15px;
            width: 100%;
        }

        .footer {
            width: 100%;
            display: flex;
            margin-top: 25px;
            justify-content: end;
            gap: 20px;
        }
    }
}