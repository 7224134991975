@import '../../styles/variables';
@import '../../styles/mixins';

// Base styles
.container {
  width: 100%;
  min-height: 100vh;
}

// Layout
.section {
  padding: 4rem 0;

  &:nth-child(even) {
    background: $white
  }
}

.sectionContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.sectionTitle {
  font-size: 1.875rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3rem;
  color: $gray-800;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;

  &.blue {
    color: $blue;
  }
}

.text {
  color: $gray-700;
  margin-bottom: 1.5rem;
}

// Buttons
.btn {
  @include button;
}

.btnPrimary {
  @include buttonPrimary;
}

.btnOutline {
  @include buttonOutline;
}

.btnWhite {
  @include buttonWhite;
}

.btnOutlineWhite {
  @include buttonOutlineWhite;
}

.btnLarge {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
}

// Hero Section
.hero {
  position: relative;
  padding: 5rem 0 8rem;
  background-color: $gray-100;
  min-height: calc(100vh - 350px);

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-size: cover;
    background-position: center;
  }
}

.heroOverlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  background: url("../../../public/img/gavel-7499911_1920.jpg");
  background-position: center;
}

.heroContent {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.heroText {
  margin: 0 auto 6rem;
  text-align: center;

  h2 {
    font-size: 2rem;
    font-weight: 700;
    color: $white;
    margin-bottom: 3rem;

    @include respond-to('md') {
      font-size: 3rem;
    }
  }

  p {
    font-size: 1.25rem;
    color: $white;
    margin-bottom: 2rem;
  }
}

// Search Form
.searchContainer {
  max-width: 64rem;
  margin: 0 auto;
  background-color: $white;
  border-radius: $radius;
  box-shadow: $shadow-lg;
  padding: 1.5rem;
}

.searchTabs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.tabBtn {
  padding: 0.75rem;
  text-align: center;
  background-color: $gray-100;
  border: none;
  border-radius: $radius;
  cursor: pointer;
  transition: $transition;

  &.active {
    background-color: $blue;
    color: $white;
  }
}

.tabContent {
  display: block;
}

.searchForm {
  display: flex;
  gap: 1rem;
  align-items: end;
  margin: 3rem 0;
  width: 100%;

  @media (max-width: 650px) {
    flex-wrap: wrap;
    justify-content: end;
  }
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 100%;

  label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: $gray-700;
    margin-bottom: 0.25rem;
  }
}

.formControl {
  max-width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid $gray-300;
  border-radius: $radius;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: $orange;
    box-shadow: 0 0 0 2px rgba(249, 115, 22, 0.2);
  }
}

.searchBtn {
  height: 2.5rem;
}

.searchInfo {
  margin-top: 1rem;
  text-align: center;
  font-size: 0.875rem;
  color: $gray-500;
}

.icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

// Two Columns Layout
.twoColumns {
  display: grid;
  gap: 3rem;

  @include respond-to('md') {
    grid-template-columns: repeat(2, 1fr);
  }

  .column h3 {
    margin-top: 0;
  }

  &.reverse {
    direction: ltr;

    @include respond-to('md') {
      .column:first-child {
        order: 2;
      }

      .column:last-child {
        order: 1;
      }
    }
  }
}

.featureImage {
  border-radius: $radius;
  box-shadow: $shadow-md;
  width: 100%;
  height: auto;
}

// Benefits List
.benefitsList {
  list-style: none;
  margin-bottom: 2rem;
}

.benefitItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.75rem;
}

.checkIcon {
  width: 1.25rem;
  height: 1.25rem;
  color: $orange;
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.ctaContainer {
  margin-top: 2rem;
}

// Features Grid
.featuresGrid {
  display: grid;
  gap: 2rem;

  @include respond-to('sm') {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond-to('lg') {
    grid-template-columns: repeat(3, 1fr);
  }
}

.featureCard {
  background-color: $white;
  padding: 1.5rem;
  border-radius: $radius;
  box-shadow: $shadow-md;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: $shadow-lg;
  }
}

.featureIconContainer {
  width: 3rem;
  height: 3rem;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  &.blue {
    background-color: $blue-light;

    .featureIcon {
      color: $blue;
    }
  }

  &.orange {
    background-color: $orange-site-light;

    .featureIcon {
      color: $orange;
    }
  }
}

.featureIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.featureTitle {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: $gray-800;
}

.featureText {
  color: $gray-600;
}

// Testimonials
.testimonialsGrid {
  display: grid;
  gap: 2rem;

  @include respond-to('sm') {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond-to('lg') {
    grid-template-columns: repeat(3, 1fr);
  }
}

.testimonialCard {
  background-color: $white;
  padding: 1.5rem;
  border-radius: $radius;
  box-shadow: $shadow;
}

.testimonialHeader {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.testimonialAvatar {
  width: 3rem;
  height: 3rem;
  background-color: $gray-200;
  border-radius: 9999px;
  margin-right: 1rem;
}

.testimonialAuthor {
  h4 {
    font-weight: 600;
  }

  p {
    font-size: 0.875rem;
    color: $gray-500;
  }
}

.testimonialText {
  color: $gray-600;
  font-style: italic;
}

// CTA Section
.ctaSection {
  background-color: $blue;
  color: $white;
  padding: 4rem 0;
}

.ctaTitle {
  font-size: 1.875rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1.5rem;
}

.ctaText {
  font-size: 1.25rem;
  text-align: center;
  max-width: 32rem;
  margin: 0 auto 2rem;
}

.ctaButtons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  @include respond-to('sm') {
    flex-direction: row;
  }
}

// Contact Section
.contactInfo {
  margin-bottom: 2rem;
}

.contactItem {
  display: flex;
  margin-bottom: 1.5rem;
}

.contactIcon {
  width: 1.25rem;
  height: 1.25rem;
  color: $orange;
  margin-top: 0.25rem;
  margin-right: 0.75rem;
}

.contactItem {
  h4 {
    font-weight: 500;
    margin-bottom: 0.25rem;
  }

  p {
    color: $gray-600;
  }
}

.socialLinks {
  h4 {
    font-weight: 500;
    margin-bottom: 0.75rem;
  }
}

.socialIcons {
  display: flex;
  gap: 1rem;
}

.socialIcon {
  color: $gray-600;
  transition: $transition;

  &:hover {
    color: $orange;
  }
}

.contactForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.formRow {
  display: grid;
  gap: 1rem;

  @include respond-to('sm') {
    grid-template-columns: repeat(2, 1fr);
  }
}