@import "../../styles/variables.scss";

.btn {
    display: flex;
    align-items: center;
    text-decoration: none;
    border-radius: 5px;
    border: none;
    text-align: center;
    min-width: fit-content;
    font-weight: bold;
    padding: 5px 10px;
    font-size: 15px;
    color: inherit;
    background-color: inherit;
    gap: 5px;
    cursor: pointer;

    &:disabled {
        background: #ffe9d4;
        color: #bababa;
        cursor: not-allowed;
    }
}

.primary {
    color: #ffffff;
    background-color: $orange;
}

.primaryLight {
    color: $orange;
    background-color: #ffffff00;
}

.primaryLightBordered {
    color: $orange;
    background-color: #ffffff;
    border: 2px solid $orange;
}

.secondary {
    color: #ffffff;
    background-color: $blue-site;
}

.secondaryLight {
    color: $blue-site;
    background-color: #ffffff00;
}

.tertiary {
    color: #ffffff;
    background-color: #ffffff00;
}

.secondaryLightBordered {
    color: $blue-site;
    background-color: #ffffff10;
    border: 2px solid $blue-site;
}

.icon {
    margin-right: 10px;
}