.search {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    background-color: #1e52a445;
    border-radius: 5px;

    .input {
        border: 0;
        padding: 0 10px;
    }
}