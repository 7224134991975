@import "./fontawesome/css/all.css";
@import "./styles/variables.scss";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $blue-site-transparent;
  color: $blue-site;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.fc-toolbar-title {
  font-size: clamp(5px, 3.5vw, 20px) !important;
}

.fc-non-business {
  background-color: #ffe1e1 !important;
  cursor: not-allowed;
}

.fc-event {
  background-color: #ffe1e1 !important;
  cursor: not-allowed;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0);
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #20468378;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $blue-site;
}


.fa-folder {
  color: $case-color;
}

pre {
  font-family: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid #1e52a445;
  border-radius: 5px;
  overflow: hidden;

  thead {
    background-color: $blue-site-light;
  }

  th,
  td {
    padding: 10px 0;

    a {
      margin: auto;
    }

  }
}

.fc {
  .fc-scrollgrid {
    border-radius: 5px;
  }

  table {
    border-radius: 0;
  }
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 10px;
}

.fc-toolbar-chunk {

  .fc-prev-button.fc-button.fc-button-primary,
  .fc-next-button.fc-button.fc-button-primary,
  .fc-today-button.fc-button.fc-button-primary {
    background: $blue-site;
    border-color: $blue-site;
  }
}

.fc-theme-standard .fc-list {
  border: 1px solid rgb(196 208 231);
  border-radius: 5px;
}

.fc .fc-list-sticky .fc-list-day>* {
  background: #c4d0e7;
}

.fc-scrollgrid-sync-inner {
  padding: 7px;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid rgb(196 208 231);
}

.fc .fc-list-day-cushion {
  padding: 10px 14px;
}

.success {
  background: #d0e486;
}

.error {
  background: #f7a899;
}

.inProgress {
  background: #fbdf72;
}