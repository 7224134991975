// Variables
$orange: #f97316;
$orange-light: #fff7ed;
$orange-dark: #ea580c;
$blue: #2563eb;
$blue-light: #eff6ff;
$blue-dark: #1d4ed8;
$purple: #9333ea;
$purple-light: #f5f3ff;
$purple-dark: #7e22ce;
$yellow: #eab308;
$yellow-light: #fef9c3;
$yellow-dark: #ca8a04;
$green: #22c55e;
$green-light: #dcfce7;
$green-dark: #16a34a;
$gray-50: #f9fafb;
$gray-100: #f3f4f6;
$gray-200: #e5e7eb;
$gray-300: #d1d5db;
$gray-400: #9ca3af;
$gray-500: #6b7280;
$gray-600: #4b5563;
$gray-700: #374151;
$gray-800: #1f2937;
$gray-900: #111827;
$white: #ffffff;
$black: #000000;
$shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
$shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
$shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
$radius: 0.5rem;
$radius-lg: 0.75rem;
$transition: all 0.2s ease;

// Breakpoints
$breakpoint-sm: 640px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;

// Mixins
@mixin respond-to($breakpoint) {
    @if $breakpoint =="sm" {
        @media (min-width: $breakpoint-sm) {
            @content;
        }
    }

    @else if $breakpoint =="md" {
        @media (min-width: $breakpoint-md) {
            @content;
        }
    }

    @else if $breakpoint =="lg" {
        @media (min-width: $breakpoint-lg) {
            @content;
        }
    }

    @else if $breakpoint =="xl" {
        @media (min-width: $breakpoint-xl) {
            @content;
        }
    }
}

@mixin button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.5rem;
    font-weight: 500;
    border-radius: $radius;
    cursor: pointer;
    transition: $transition;
    border: none;
    text-decoration: none;
    text-align: center;
}

// Base styles
.container {
    width: 100%;
    min-height: 100vh;
    background-color: $gray-50;
}

// Header
.header {
    background-color: $white;
    box-shadow: $shadow-sm;
    padding: 1rem 0;
}

.headerContent {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    font-size: 1.5rem;
    font-weight: 700;
    color: $orange;
    text-decoration: none;
}

.navigation {
    display: none;

    @include respond-to('md') {
        display: flex;
        gap: 1.5rem;
    }
}

.navLink {
    color: $gray-700;
    text-decoration: none;
    font-weight: 500;
    transition: $transition;

    &:hover {
        color: $orange;
    }

    &.active {
        color: $orange;
        font-weight: 600;
    }
}

// Hero Section
.hero {
    background-color: $blue;
    color: $white;
    padding: 5rem 0;
    text-align: center;
}

.heroContent {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1rem;
}

.heroTitle {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;

    @include respond-to('md') {
        font-size: 3rem;
    }
}

.heroSubtitle {
    font-size: 1.25rem;
    max-width: 700px;
    margin: 0 auto;

    @include respond-to('md') {
        font-size: 1.5rem;
    }
}

// Section Layout
.section {
    padding: 4rem 0;
}

.sectionContent {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 1rem;
}

.sectionTitle {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2.5rem;
    color: $gray-800;
    text-align: center;

    @include respond-to('md') {
        font-size: 2.25rem;
    }
}

.titleIcon {
    margin-right: 0.75rem;
}

// Introduction
.introContainer {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.introText {
    font-size: 1.125rem;
    color: $gray-700;
    margin-bottom: 2rem;
    line-height: 1.6;
}

.tagContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.tag {
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    font-weight: 500;
}

.tagBlue {
    background-color: $blue-light;
    color: $blue;
}

.tagOrange {
    background-color: $orange-light;
    color: $orange;
}

.tagGreen {
    background-color: $green-light;
    color: $green;
}

.infoBox {
    background-color: $blue-light;
    border: 1px solid rgba($blue, 0.2);
    border-radius: $radius;
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    text-align: left;
}

.infoIcon {
    color: $blue;
    margin-right: 0.75rem;
    margin-top: 0.25rem;
    flex-shrink: 0;
}

.infoText {
    color: $blue-dark;
    font-size: 0.875rem;
}

// Pricing Toggle
.toggleSection {
    padding: 2rem 0;
}

.billingToggle {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
    flex-wrap: wrap;

    @include respond-to('md') {
        flex-wrap: nowrap;
    }
}

.toggleButton {
    padding: 0.75rem 1.5rem;
    border-radius: $radius;
    font-weight: 500;
    background-color: $gray-100;
    color: $gray-700;
    border: none;
    cursor: pointer;
    transition: $transition;

    &:hover {
        background-color: $gray-200;
    }

    &.active {
        background-color: $blue;
        color: $white;
    }
}

// Pricing Cards
.pricingGrid {
    display: grid;
    gap: 2rem;

    @include respond-to('md') {
        grid-template-columns: repeat(2, 1fr);
    }

    @include respond-to('lg') {
        grid-template-columns: repeat(5, 1fr);
    }
}

.pricingCard {
    background-color: $white;
    border-radius: $radius-lg;
    overflow: hidden;
    box-shadow: $shadow-md;
    border: 1px solid $gray-200;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-5px);
        box-shadow: $shadow-lg;
    }

    &.featuredCard {
        border: 2px solid $blue;
        position: relative;
        transform: scale(1.05);
        z-index: 6;

        @include respond-to('md') {
            margin-top: -1rem;
            margin-bottom: -1rem;
        }
    }
}

.featuredBadge {
    background-color: $blue;
    color: $white;
    text-align: center;
    padding: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
}

.pricingHeader {
    padding: 1.5rem;
    border-bottom: 1px solid $gray-200;
    text-align: center;

    &.testHeader {
        background-color: $gray-50;
    }
}

.iconContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.planIcon {
    font-size: 1.875rem;
    color: $purple;

    &.basicIcon {
        color: $yellow;
    }

    &.advancedIcon {
        color: $blue;
    }

    &.premiumIcon {
        color: $purple;
    }

    &.payPerUseIcon {
        color: $green;
    }
}

.planTitle {
    font-size: 1.5rem;
    font-weight: 700;
    color: $gray-800;
    margin-bottom: 0.5rem;
}

.planSubtitle {
    color: $gray-600;
    margin-bottom: 1rem;
}

.priceContainer {
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.price {
    font-size: 2.25rem;
    font-weight: 700;
    color: $gray-900;
}

.period {
    color: $gray-500;
    margin-left: 0.25rem;
}

.billingInfo {
    font-size: 0.875rem;
    color: $gray-500;
    margin-top: 0.25rem;
}

.pricingBody {
    padding: 1.5rem;
}

.featuresList {
    list-style: none;
    margin-bottom: 2rem;
    padding: 0;
}

.featureItem {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.featureIconIncluded {
    color: $green;
    margin-right: 0.5rem;
    margin-top: 0.25rem;
    flex-shrink: 0;
}

.featureIconExcluded {
    color: $gray-400;
    margin-right: 0.5rem;
    margin-top: 0.25rem;
    flex-shrink: 0;
}

.featureExcluded {
    color: $gray-500;
}

.planButton {
    width: 100%;
    padding: 0.75rem 1rem;
    border-radius: $radius;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: $transition;

    &.testButton {
        background-color: $purple;
        color: $white;

        &:hover {
            background-color: $purple-dark;
        }
    }

    &.basicButton {
        background-color: $yellow;
        color: $white;

        &:hover {
            background-color: $yellow-dark;
        }
    }

    &.advancedButton {
        background-color: $blue;
        color: $white;

        &:hover {
            background-color: $blue-dark;
        }
    }

    &.premiumButton {
        background-color: $purple;
        color: $white;

        &:hover {
            background-color: $purple-dark;
        }
    }

    &.payPerUseButton {
        background-color: $green;
        color: $white;

        &:hover {
            background-color: $green-dark;
        }
    }
}

// Custom Plan
.customPlan {
    background-color: $gray-100;
    border-radius: $radius-lg;
    padding: 2rem;
    text-align: center;
    margin-top: 4rem;
}

.customPlanTitle {
    font-size: 1.5rem;
    font-weight: 700;
    color: $gray-800;
    margin-bottom: 1rem;
}

.customPlanText {
    color: $gray-700;
    margin-bottom: 1.5rem;
    max-width: 48rem;
    margin-left: auto;
    margin-right: auto;
}

.customPlanButton {
    background-color: $orange;
    color: $white;
    padding: 0.75rem 1.5rem;
    border-radius: $radius;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: $transition;

    &:hover {
        background-color: $orange-dark;
    }
}

// Simulator
.simulatorSection {
    background-color: $blue-light;
}

.simulatorIntro {
    font-size: 1.125rem;
    color: $gray-700;
    text-align: center;
    margin-bottom: 2.5rem;
    max-width: 48rem;
    margin-left: auto;
    margin-right: auto;
}

.simulatorCard {
    background-color: $white;
    border-radius: $radius-lg;
    padding: 2rem;
    box-shadow: $shadow-md;
}

.simulatorGrid {
    display: grid;
    gap: 2rem;

    @include respond-to('md') {
        grid-template-columns: repeat(2, 1fr);
    }
}

.simulatorSubtitle {
    font-size: 1.25rem;
    font-weight: 600;
    color: $gray-800;
    margin-bottom: 1.5rem;
}

.paramGroup {
    margin-bottom: 1.5rem;
}

.paramLabel {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: $gray-700;
    margin-bottom: 0.5rem;
}

.planButtonsGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
}

.planTypeButton {
    padding: 0.5rem 0.75rem;
    border-radius: $radius;
    font-size: 0.875rem;
    font-weight: 500;
    background-color: $gray-100;
    color: $gray-700;
    border: none;
    cursor: pointer;
    transition: $transition;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: $gray-200;
    }

    &.basicActive {
        background-color: $yellow;
        color: $white;
    }

    &.advancedActive {
        background-color: $blue;
        color: $white;
    }

    &.premiumActive {
        background-color: $purple;
        color: $white;
    }

    &.payPerUseActive {
        background-color: $green;
        color: $white;
    }
}

.buttonIcon {
    margin-right: 0.5rem;
}

.billingButtonsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.75rem;
}

.billingTypeButton {
    padding: 0.5rem 0.75rem;
    border-radius: $radius;
    font-size: 0.875rem;
    font-weight: 500;
    background-color: $gray-100;
    color: $gray-700;
    border: none;
    cursor: pointer;
    transition: $transition;

    &:hover {
        background-color: $gray-200;
    }

    &.active {
        background-color: $blue;
        color: $white;
    }
}

.inputField {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid $gray-300;
    border-radius: $radius;
    font-size: 1rem;
    transition: $transition;

    &:focus {
        outline: none;
        border-color: $blue;
        box-shadow: 0 0 0 2px rgba($blue, 0.2);
    }
}

.simulatorResults {
    background-color: $gray-50;
    padding: 1.5rem;
    border-radius: $radius;
}

.resultsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.resultRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $gray-200;

    &.totalRow {
        font-size: 1.125rem;
    }
}

.resultLabel {
    color: $gray-700;
}

.resultValue {
    font-weight: 600;
    color: $gray-900;
}

.totalLabel {
    font-weight: 500;
    color: $gray-800;
}

.totalValue {
    font-weight: 700;
    color: $blue;
}

.savingsBox {
    margin-top: 1.5rem;
    padding: 1rem;
    background-color: $green-light;
    border: 1px solid rgba($green, 0.2);
    border-radius: $radius;
}

.savingsRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.savingsLabel {
    font-weight: 500;
    color: $green-dark;
}

.savingsValue {
    font-weight: 700;
    color: $green;
}

.extraCostBox {
    margin-top: 1.5rem;
    padding: 1rem;
    background-color: $orange-light;
    border: 1px solid rgba($orange, 0.2);
    border-radius: $radius;
}

.extraCostRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.extraCostLabel {
    font-weight: 500;
    color: $orange-dark;
}

.extraCostValue {
    font-weight: 700;
    color: $orange;
}

.disclaimer {
    margin-top: 1.5rem;
    font-size: 0.875rem;
    color: $gray-500;
    font-style: italic;
}

// Comparison Table
.comparisonSection {
    background-color: $white;
}

.tableContainer {
    overflow-x: auto;
}

.comparisonTable {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
        padding: 1rem 1.5rem;
        border-bottom: 1px solid $gray-200;
    }

    th {
        text-align: center;
        font-weight: 600;
        color: $gray-700;
        background-color: $gray-50;

        &:first-child {
            text-align: left;
        }

        &.featuredColumn {
            color: $blue;
        }
    }

    td {
        text-align: center;

        &:first-child {
            text-align: left;
            font-weight: 500;
        }

        &.featuredColumn {
            background-color: rgba($blue, 0.05);
        }
    }

    .featureColumn {
        width: 25%;
    }
}

// FAQ Section
.faqSection {
    background-color: $gray-50;
}

.faqContainer {
    max-width: 800px;
    margin: 0 auto;
}

.faqItem {
    background-color: $white;
    padding: 1.5rem;
    border-radius: $radius;
    box-shadow: $shadow-md;
    margin-bottom: 1.5rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.faqQuestion {
    font-size: 1.25rem;
    font-weight: 600;
    color: $gray-800;
    margin-bottom: 0.75rem;
}

.faqAnswer {
    color: $gray-700;
    line-height: 1.6;
}

// CTA Section
.ctaSection {
    background-color: $blue;
    color: $white;
    padding: 4rem 0;
    text-align: center;
}

.ctaTitle {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;

    @include respond-to('md') {
        font-size: 2.25rem;
    }
}

.ctaText {
    font-size: 1.25rem;
    max-width: 48rem;
    margin: 0 auto 2rem;
}

.ctaButtons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;

    @include respond-to('sm') {
        flex-direction: row;
    }
}

.ctaButtonWhite {
    background-color: $white;
    color: $blue;
    padding: 0.75rem 2rem;
    border-radius: $radius;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: $transition;

    &:hover {
        background-color: $gray-100;
    }
}

.ctaButtonOrange {
    background-color: $orange;
    color: $white;
    padding: 0.75rem 2rem;
    border-radius: $radius;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: $transition;

    &:hover {
        background-color: $orange-dark;
    }
}

// Footer
.footer {
    background-color: $gray-800;
    color: $white;
    padding: 3rem 0;
}

.footerContent {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 1rem;
}

.footerGrid {
    display: grid;
    gap: 2rem;

    @include respond-to('sm') {
        grid-template-columns: repeat(2, 1fr);
    }

    @include respond-to('lg') {
        grid-template-columns: repeat(4, 1fr);
    }
}

.footerColumn {
    h3 {
        font-size: 1.125rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }
}

.footerTitle {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.footerText {
    color: $gray-400;
    margin-bottom: 0.5rem;
}

.footerLinks {
    list-style: none;
    padding: 0;

    li {
        margin-bottom: 0.5rem;
    }
}

.footerLink {
    color: $gray-400;
    text-decoration: none;
    transition: $transition;

    &:hover {
        color: $white;
    }
}

.footerBottom {
    border-top: 1px solid $gray-700;
    margin-top: 2rem;
    padding-top: 2rem;
    text-align: center;
    color: $gray-400;
}