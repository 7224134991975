.container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0 100px 50px 100px;

    .btn {
        display: flex;
        justify-content: end;
        width: 100%;
        margin-top: 25px;
    }

    .settings {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}