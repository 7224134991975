.container {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 10px;
    }

.optionContainer {
    display: flex;
    flex-wrap:wrap;
        gap: 10px;
    }

.inputContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    .input {
            height: 25px;
            width: 25px;
        }
        label{
            width: 100%;
        }
    }

