@import "../../../../styles/variables.scss";

.meetings {
    display: flex;
    width: 100%;

    table {
        margin-top: 10px;
        width: 100%;
        border-spacing: 0;

        thead {
            // background-color: #1e52a445;
            background-color: $blue-site-light;
        }

        th,
        td {
            padding: 10px 0;

            .action {
                display: flex;
                justify-content: center;
            }
        }
    }
}