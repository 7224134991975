@import '../../../styles/variables';
@import '../../../styles/mixins';

.header {
  position: sticky;
  top: 0;
  z-index: 7;
  background-color: $white;
  box-shadow: $shadow-sm;
}

.headerContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @include respond-to('md') {
    flex-wrap: nowrap;
  }
}

.logo {
  display: flex;
  align-items: center;
  z-index: 4;
}

.logoLink {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logoImage {
  margin-right: 0.75rem;
}

.logoText {
  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    color: $orange;
    margin: 0;
  }

  span {
    display: block;
    font-size: 0.875rem;
    color: $gray-600;
    width: 200px;
  }
}

// Mobile menu button
.mobileMenuBtn {
  display: block;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: $gray-700;
  cursor: pointer;
  z-index: 4;

  @include respond-to('md') {
    display: none;
  }
}

.mainNav {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  padding: 5rem 2rem 2rem;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 3;

  &.open {
    padding: 6rem 0 0;
    transform: translateX(0);
  }

  @include respond-to('md') {
    position: static;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    padding: 0;
    transform: none;
    background-color: transparent;
  }
}

.navLink {
  font-weight: 500;
  color: $gray-700;
  text-decoration: none;
  transition: $transition;
  padding: 0.75rem 0;
  font-size: 1.125rem;

  @include respond-to('md') {
    padding: 0;
    font-size: 1rem;
  }

  &:hover {
    color: $orange;
  }
}

.authButtons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 5rem;

  &.open {
    margin-top: 5rem;
    margin-left: 0;
  }

  @include respond-to('md') {
    flex-direction: row;
    gap: 0.5rem;
  }
}

// Buttons
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-weight: 500;
  border-radius: $radius;
  cursor: pointer;
  transition: $transition;
  border: none;
  text-decoration: none;
  text-align: center;
}

.btnPrimary {
  background-color: $orange;
  color: $white;

  &:hover {
    background-color: $orange-dark;
  }
}

.btnOutline {
  background-color: transparent;
  border: 1px solid $blue;
  color: $blue;

  &:hover {
    background-color: $blue-light;
  }
}