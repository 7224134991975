@import "../../../../styles/variables.scss";

.billing {
    padding: 25px;
    display: flex;
    justify-content: center;

    .billingContainer {
        position: relative;
        box-shadow: 5px 5px 10px grey;
        display: flex;
        flex-direction: column;
        padding: 50px;
        height: calc(3508px / 3);
        width: calc(2480px / 3);

        .billingHeader {
            display: flex;
            justify-content: space-between;
            margin-bottom: 100px;

            .billingLawfirmLogo {
                display: flex;
                flex-direction: column;
                justify-items: start;
            }

            .billingTitle {
                display: flex;
                flex-direction: column;
                justify-items: end;

                .infoTitle {
                    display: flex;
                    justify-content: end;

                    .titleLabel {
                        margin-right: 5px;
                    }
                }
            }
        }

        .billingInfo {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 100px;

            .billingLawfirmInfo {
                display: flex;
                flex-direction: column;
            }

            .billingClientInfo {
                display: flex;
                flex-direction: column;
            }
        }

        .billingRef {
            width: 100%;
            text-align: center;
            margin-bottom: 100px;
        }

        .billingBody {
            width: 100%;
            display: flex;
            margin-bottom: 50px;

            .billingTable {
                width: 100%;
                border-spacing: 0 10px;

                thead {
                    background: #a4a4a4;
                    color: #ffffff;
                }

                th,
                td {
                    text-align: center;
                }

                tfoot {
                    border-top: 2px solid #a4a4a4;
                }

            }
        }

        .payment {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 10px;

            .paymentInfo {
                display: flex;

                .paymentLabel {
                    width: 200px;
                }

                .paymentData {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .billingFooter {
            display: flex;
            flex-direction: column;
            position: absolute;
            width: 100%;
            bottom: 10px;
            left: 0;
            text-align: center;
            font-size: 10px;
        }
    }
}