@import "../../../../styles/variables.scss";


.conversation {
    background-color: $bluegray-site;
    padding: 25px;
    border-radius: 5px;
    color: #ffffff;

    .messages {
        padding: 25px;
        overflow-y: auto;
        height: calc(100vh - 360px);
    }

    .user {
        font-size: 12px;
    }

    .date {
        font-size: 10px;
        width: 100%;
        text-align: end;
    }

    .msg {
        span {
            margin-bottom: 5px;
        }
    }

    .left {
        display: flex;
        margin: 5px;
        justify-content: start;

        .msg {
            border-radius: 10px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: start;
            background-color: $blue-site;

            strong {
                margin-bottom: 5px;
            }
        }
    }

    .right {
        display: flex;
        margin: 5px;
        justify-content: end;

        .msg {
            border-radius: 10px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: end;
            background-color: $orange;
        }
    }

    .action {
        margin-top: 15px;
        width: 100%;
        display: flex;

        .write {
            width: 90%;

            input {
                color: #ffffff;
            }
        }

        .send {
            display: flex;
            justify-content: end;
            width: 10%;
        }
    }
}