@import "../../styles/variables.scss";


.container {
    display: flex;
    padding: 10px;
    min-height: 100vh;
}

.pagesContainer {
    width: 100%;
    padding-left: 15px;
    min-height: inherit;
}

.body {
    max-width: 100%;
    padding: 70px 200px;

    .bodyInfo {
        display: flex;
        flex-direction: column;
        background-color: $blue-site-transparent;
        padding: 70px 50px;
        gap: 20px;
        border-radius: 10px;

    }

}

.title {
    text-align: center;
}

.userContainer {
    display: flex;
    width: 100%;
    align-items: start;
    gap: 10px;
    padding: 15px;

    .photoContainer {
        position: relative;

        .photoCard {
            height: 50px;
            width: 50px;
            border-radius: 100px;
            background-color: $blue-site;
            border: 3px solid $orange;
            background-size: cover;
            background-repeat: repeat;
            background-position: center;
            color: transparent;
        }
    }

    .identity {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .nameContainer {
            font-size: 20px;
            color: #ffffff;
        }

        .btn {
            button {
                font-size: 13px;
                font-weight: lighter;
                color: #ff0000;
                padding: 0;
            }

            span {
                margin-left: 5px;
                color: #ffffff;
            }

            :hover {
                color: #ff0000;
            }
        }
    }
}