@import '../../../styles/variables';
@import '../../../styles/mixins';

.footer {
  background-color: $gray-800;
  color: $white;
  padding: 3rem 0;
}

.footerContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.footerGrid {
  display: grid;
  gap: 2rem;

  @include respond-to('sm') {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond-to('lg') {
    grid-template-columns: repeat(4, 1fr);
  }
}

.footerColumn {
  h3 {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  p {
    color: $gray-400;
    margin-bottom: 1rem;
  }
}

.footerLinks {
  list-style: none;

  li {
    margin-bottom: 0.5rem;
  }
}

.footerLink {
  color: $gray-400;
  text-decoration: none;
  transition: $transition;

  &:hover {
    color: $white;
  }
}

.newsletterForm {
  display: flex;
  margin-top: 1rem;
}

.newsletterInput {
  flex-grow: 1;
  padding: 0.5rem 1rem;
  border: none;
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
}

.newsletterButton {
  background-color: $orange;
  color: $white;
  border: none;
  padding: 0.5rem 1rem;
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
  cursor: pointer;
  transition: $transition;

  &:hover {
    background-color: $orange-dark;
  }
}

.footerBottom {
  border-top: 1px solid $gray-700;
  margin-top: 2rem;
  padding-top: 2rem;
  text-align: center;
  color: $gray-400;
}