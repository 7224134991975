@import "../../../styles/variables.scss";

.body {
  max-width: 100%;
  padding: 70px 5%;

  @media (max-width:$breakpoint-md) {
    padding: 70px 2%;
  }

  .bodyInfo {
    display: flex;
    flex-direction: column;
    background-color: $blue-site-transparent;
    padding: 100px 10%;
    gap: 20px;
    border-radius: 10px;

    @media (max-width:$breakpoint-md) {
      padding: 20px 5%;
    }

    .bodyContainer {
      padding: 30px 50px;

      @media (max-width:$breakpoint-md) {
        padding: 20px 3%;
      }
    }

    .bodyContact {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;

      div {
        width: calc(100% / 3);
        margin-bottom: 15px;

        span {
          display: flex;
          color: $blue-site;
          font-size: clamp(8px, 2.5vw, 20px);
        }

        .icon {
          display: flex;
          padding: 10px;
          border-radius: 25px;
          align-items: center;
          gap: 10px;

          i {
            font-size: clamp(8px, 5vw, 30px);
          }
        }
      }
    }

    .bodySpeciality {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .chip {
        font-size: 20px;
        font-weight: bold;
        padding: 10px 20px;
        color: $orange;
        background-color: $blue-site;
        border-radius: 20px;
      }
    }

    .bodyDescription {

      p {
        padding: 0 7vw;
      }
    }

    .bodyMeeting {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      h3 {
        width: 100%;
      }

      .calendar {
        width: 100%;
      }
    }

    .bodyPrice {

      p {
        padding: 0 100px;
      }
    }
  }
}