.label {
    font-size: 20px;
    font-weight: bold;
}

.email {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;

    .input {
        width: 100%;
    }
}