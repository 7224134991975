@import "../../../styles/variables.scss";

.body {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding: 20px;
    gap: 50px;

    h1 {
        text-align: center;
        text-decoration: underline 3px solid $orange;
        text-underline-position: under;
    }


    .container {
        padding: 50px;
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
        width: 500px;
        justify-content: center;

        div {
            width: 100%;
        }

        .action {
            display: flex;
            justify-content: end;
        }
    }
}