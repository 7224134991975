.container {
    position: sticky;
    top: 20px;
    z-index: 7;

    .notification {
        position: absolute;
        display: flex;
        align-items: center;
        min-height: 25px;
        width: fit-content;
        color: black;
        top: 10px;
        right: 20px;
        background-color: rgb(255, 255, 255);
        padding: 5px 10px;
        border-radius: 5px;
        box-shadow: 5px 5px 10px grey;
    }

    .error {
        background-color: rgb(210, 0, 0);
        color: white;
    }

    .success {
        background-color: rgb(0, 135, 14);
        color: white;
    }

    .pending {
        background-color: rgb(250, 238, 141);
        color: black;
    }
}