@import "../../styles/variables.scss";

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
    height: calc(100vh - 200px);
    gap: 20px;

    .formContainer {
        background-color: $blue-site-transparent;
        display: flex;
        overflow-y: scroll;
        border-radius: 10px;
        width: calc(100% - 50px);
        padding: 25px;

        .formElementsContainer {
            width: 100%;
            margin-bottom: 50px;
        }
    }

    .buttonContainer {
        display: flex;
        width: 100%;
        justify-content: end;
        gap: 10px;
    }

}