.container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .availabilities {
        display: flex;
        width: 100%;

        .card {
            width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            display: flex;
            padding: 15px;
            gap: 20px;

            .btnAvailabilities{
                width: 100%;
                display: flex;
                justify-content: space-evenly;
            }
            .btnAvailabilitiesSave{
                display: flex;
                width: 100%;
                justify-content: end;
            }
        }
    }

    .containerCalendar {
        display: flex;
        width: 100%;

        .eventContainer {
            width: 20%;

            .events {
                margin-top: 10px;
                height: 250px;
                overflow-y: auto;
                display: flex;
                flex-wrap: wrap;
                align-content: start;

                .event{
                    width: 100%;
                    margin: 5px 0;
                }
            }
        }

        .calendar {
            width: 80%;
            padding: 0 20px;
        }
    }
}

.card {
    padding: 15px;
}