@import "../../../../../styles/variables.scss";

.inputContainer {
    display: flex;
    align-items: start;
    gap: 10px;
    background: $blue-site-transparent ;
    border-radius: 10px;
    padding: 15px;
    border-bottom: 1px solid $blue-site-transparent;
}