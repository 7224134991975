@import "../../../styles/variables.scss";

.cover {
    height: 350px;
    background: linear-gradient(to right, $blue-site, $orange);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 5px 5px 10px grey;

    @media (max-width:$breakpoint-md) {
        height: 150px;
        padding: 0 20px;
    }

    .photoContainer {
        display: flex;
        position: relative;
        justify-content: center;
        height: 100%;
        align-items: end;

        .bodyId {
            text-align: center;
            top: 271px;
            padding: 10px 50px;
            font-weight: bold;
            background: #ffffffab;
            border-radius: 15px 15px 0 0;
            font-size: clamp(8px, 6vw, 40px);
        }
    }
}

.body {
    max-width: 100%;
    padding: 70px 200px;

    @media (max-width:$breakpoint-md) {
        padding: 30px 2%;
    }

    .bodyInfo {
        display: flex;
        flex-direction: column;
        background-color: $blue-site-transparent;
        padding: 100px 10%;
        gap: 20px;
        border-radius: 10px;

        @media (max-width:$breakpoint-md) {
            padding: 20px 5%;
        }

        .bodyContainer {
            padding: 30px 50px;

            @media (max-width:$breakpoint-md) {
                padding: 20px 3%;
            }
        }

        .bodyContact {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            max-width: 100%;



            div {
                width: calc(100% / 3);
                margin-bottom: 15px;

                span {
                    display: flex;
                    color: $blue-site;
                    font-size: clamp(8px, 2.5vw, 20px);
                }

                .icon {
                    display: flex;
                    padding: 10px;
                    border-radius: 25px;
                    align-items: center;
                    gap: 10px;

                    i {
                        font-size: clamp(8px, 5vw, 30px);
                    }
                }
            }

        }

        .bodyLawyers {
            display: flex;
            flex-direction: column;

            .lawyerList {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 20px;

                .lawyer {
                    display: flex;
                    max-width: 150px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    .photoCard {
                        height: 100px;
                        width: 100px;
                        border-radius: 100px;
                        background-color: $blue-site;
                        border: 2px solid $orange;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                        color: transparent;

                        .photo {
                            border-radius: 100px;
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
            }

        }
    }
}