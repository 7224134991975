@import "../../../styles/variables.scss";

.cardContainer {
    width: 400px;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 5px 5px 10px grey;

    margin: 15px;
    border-radius: 5px;

    .coverCard {
        display: flex;
        width: 100%;
        height: 100px;
        background: linear-gradient(to right, $blue-site, $orange);
        border-radius: 5px 5px 0 0;
        align-items: center;
        justify-content: center;


        .name {
            color: white;
            padding: 10px 10px;
            font-size: 20px;
            font-weight: bold;
        }
    }



    .cardBody {
        width: 100%;
        display: flex;
        flex-direction: column;

        .cardBodyInfo {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 20px 10px 10px 10px;

            span {
                display: flex;
                gap: 10px;
            }
        }
    }

    .cardFooter {
        width: 100%;
        height: 30px;
        position: relative;
        padding: 5px 10px;
        display: flex;
        justify-content: end;
        align-self: flex-end;
    }
}