@import "../../styles/variables.scss";

.extendableButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue-site;
    color: #ffffff;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    padding: 10px;

    &:hover {
        box-shadow: 1px 1px 2px #000000;
        z-index: 5;
    }
}

.sideNav {
    min-width: 250px;
    min-height: 100%;
    background-color: $blue-site;
    color: #ffffff;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 1px 1px 3px black;

    .navSearchBar {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 25px;

        input {
            color: white;
        }
    }

    .linksContainer {
        display: flex;
        flex-direction: column;
        width: 100%;

        .sideNavBtn {
            display: flex;

            &:hover {
                background-color: $orange-site-transparent;
            }

            a,
            button {
                display: flex;
                width: 100%;
                font-size: 15px;
                gap: 15px;
                padding: 17px 25px;
            }

            .btn {
                flex-direction: column;
                text-align: left;
                width: 100%;

                .label {
                    cursor: pointer;
                }

                .subText {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;



                    .subLabel {
                        font-size: 10px;
                    }

                    .date {
                        font-size: 8px;
                        font-weight: lighter;
                    }
                }

            }
        }
    }
}

.reduceNav {
    min-width: 30px;
    min-height: 30px;
    max-height: 30px;
    align-items: center;
    position: absolute;
    padding-bottom: 25px;

    &:first-of-type+div {
        padding-left: 70px;
    }

    .selected {
        background-color: initial;

        a {
            color: white;
        }
    }

    &:hover {
        min-height: 50%;
        max-height: 100%;
        box-shadow: 5px 5px 10px grey;
        z-index: 5;

        .sideNavBtn {
            padding: 0;
        }

        .btn,
        .navHeader {
            display: flex;
        }

        .linksContainer {
            max-height: 50vh;
            overflow: auto;


            .selected {
                background-color: $orange;

                a {
                    color: white;
                }
            }
        }
    }

    .sideNavBtn {
        padding: 10px 7px;
    }

    .btn,
    .navHeader {
        display: none;
    }
}

.selected {
    background-color: $orange;

    a {
        color: white;
    }
}

.sticky {
    position: sticky;
    top: 0;
}