.pricingCard {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 250px;
    text-align: center;
}

.pricingCard h2 {
    color: #1e52a4;
    margin-bottom: 15px;
}

.price {
    font-size: 24px;
    color: #e78932;
    margin-bottom: 10px;
}

.description {
    margin-bottom: 20px;
}

.pricingCard ul {
    list-style: none;
    margin-bottom: 20px;
}

.pricingCard ul li {
    margin-bottom: 10px;
}

.btn {
    background: linear-gradient(45deg, #1e52a4, #e78932);
    border: none;
    color: #fff;
    padding: 10px 20px;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.btn:hover {
    background: linear-gradient(45deg, #e78932, #1e52a4);
}