@import "../../styles/variables.scss";

.divInput {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    .labelInput {
        display: flex;
        align-items: center;
    }

    .icon {
        position: absolute;
        top: 1.9rem;
        left: 0.75rem;
        color: $gray-500;
        width: 1rem;
        height: 1rem;
    }

    .input {
        max-width: 100%;
        padding: 0.5rem 1rem;
        border: 1px solid $gray-300;
        border-radius: $radius;
        font-size: 1rem;

        &:focus {
            outline: none;
            text-decoration: none;
            border-bottom: 1px solid #0091ff;
        }

        &:disabled {
            background: #dcdcdc;
        }
    }

    .inputIcon {
        padding-left: 2.5rem;
    }

    .valueLength {
        position: absolute;
        right: 5px;
        bottom: 10px;
        color: #afafaf;
    }

    .errorMessage {
        color: rgb(210, 0, 0);
    }
}