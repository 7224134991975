@import "../../../styles/variables.scss";

.cardContainer {
    display: flex;
    flex-wrap: wrap;
    background-color: #ffffff;

    .coverCard {
        width: 100%;
        height: 100px;
        background: linear-gradient(to right, $blue-site, $orange);
        border-radius: 10px;

        .photoContainer {
            position: relative;

            .photoCard {
                position: absolute;
                top: 50px;
                left: 10px;
                height: 100px;
                width: 100px;
                border-radius: 50px;
                background-color: $blue-site;
                border: 2px solid $orange;
                background-size: cover;
                background-repeat: repeat;
                background-position: center;
                color: transparent;
            }
        }
    }



    .cardBody {
        width: 100%;
        display: flex;
        flex-direction: column;

        .cardBodyId {
            padding: 5px 0 10px 120px;
            font-size: 15px;
            font-weight: bold;
        }

        .underlined {
            text-decoration: underline 3px solid $orange;
            text-underline-position: under;
        }

        .cardSpe {
            display: flex;
            gap: 5px;
            padding: 10px 10px 10px 10px;

            .chip {
                background-color: $blue-site;
                color: $orange;
                padding: 5px 10px;
                border-radius: 15px;
                font-size: 10px;
                font-weight: bold;
            }
        }

        .cardBodyInfo {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 10px;

            span {
                display: flex;
                gap: 10px;
            }
        }
    }

    .cardFooter {
        width: 100%;
        height: 30px;
        position: relative;
        padding: 5px 10px;
        display: flex;
        justify-content: end;
        align-self: flex-end;

        a {
            color: #ffffff;
        }
    }
}