.stat {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items:start;
    gap: 20px;
    &>div{
        padding: 15px;
    }
    .chartCardPie{
        height: 20vw;
        width: 20vw;
        padding: 10px;
    }
    .chartCardChart{
        width: 40vw;
        height: 20vw;
        padding: 10px;
    }

    .interval{
        display: flex;
        width: 100%;
        gap: 10px;
        justify-content: center;
    }
}