.container {
    display: flex;
    flex-direction: column;

    .forms {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: end;
    }

    .body {
        padding: 0 25px;
        width: 100%;
    }
}