// Variables
$orange: #f97316;
$orange-light: #fff7ed;
$orange-dark: #ea580c;
$blue: #2563eb;
$blue-light: #eff6ff;
$blue-dark: #1d4ed8;
$green: #22c55e;
$green-light: #f0fdf4;
$purple: #a855f7;
$purple-light: #faf5ff;
$gray-50: #f9fafb;
$gray-100: #f3f4f6;
$gray-200: #e5e7eb;
$gray-300: #d1d5db;
$gray-400: #9ca3af;
$gray-500: #6b7280;
$gray-600: #4b5563;
$gray-700: #374151;
$gray-800: #1f2937;
$gray-900: #111827;
$white: #ffffff;
$black: #000000;
$shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
$shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
$shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
$radius: 0.5rem;
$transition: all 0.2s ease;

// Breakpoints
$breakpoint-sm: 640px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;

// Mixins
@mixin respond-to($breakpoint) {
    @if $breakpoint =="sm" {
        @media (min-width: $breakpoint-sm) {
            @content;
        }
    }

    @else if $breakpoint =="md" {
        @media (min-width: $breakpoint-md) {
            @content;
        }
    }

    @else if $breakpoint =="lg" {
        @media (min-width: $breakpoint-lg) {
            @content;
        }
    }

    @else if $breakpoint =="xl" {
        @media (min-width: $breakpoint-xl) {
            @content;
        }
    }
}

// Base styles
.container {
    width: 100%;
    min-height: 100vh;
    background-color: $white;
}

// Hero Section
.hero {
    background-color: $blue;
    color: $white;
    padding: 5rem 0;
    text-align: center;
}

.heroContent {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1rem;
}

.heroTitle {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;

    @include respond-to("md") {
        font-size: 3rem;
    }
}

.heroSubtitle {
    font-size: 1.25rem;
    max-width: 700px;
    margin: 0 auto;

    @include respond-to("md") {
        font-size: 1.5rem;
    }
}

// Section Layout
.section {
    padding: 5rem 0;
}

.sectionContent {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.altBg {
    background-color: $gray-50;
}

.sectionTitle {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2.5rem;
    color: $gray-800;
    text-align: center;

    @include respond-to("md") {
        font-size: 2.25rem;
    }
}

.sectionSubtitle {
    font-size: 1.25rem;
    color: $gray-700;
    text-align: center;
    max-width: 800px;
    margin: 0 auto 3rem;
}

.text {
    color: $gray-700;
    font-size: 1.125rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
}

// Introduction
.introGrid {
    display: grid;
    gap: 3rem;
    align-items: center;

    @include respond-to("md") {
        grid-template-columns: repeat(2, 1fr);
    }
}

.introText {
    .sectionTitle {
        text-align: left;
        margin-bottom: 1.5rem;
    }
}

.introImage {
    display: flex;
    justify-content: center;
}

.image {
    max-width: 100%;
    border-radius: $radius;
    box-shadow: $shadow-lg;
}

// Tags
.tagContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-top: 1.5rem;
}

.tag {
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    font-weight: 500;
}

.tagOrange {
    background-color: $orange-light;
    color: $orange;
}

.tagBlue {
    background-color: $blue-light;
    color: $blue;
}

.tagGreen {
    background-color: $green-light;
    color: $green;
}

.tagPurple {
    background-color: $purple-light;
    color: $purple;
}

// Features
.featuresGrid {
    display: grid;
    gap: 2rem;

    @include respond-to("sm") {
        grid-template-columns: repeat(2, 1fr);
    }

    @include respond-to("lg") {
        grid-template-columns: repeat(3, 1fr);
    }
}

.featureCard {
    background-color: $white;
    padding: 2rem;
    border-radius: $radius;
    box-shadow: $shadow-md;
    transition: box-shadow 0.3s ease, transform 0.3s ease;

    &:hover {
        box-shadow: $shadow-lg;
        transform: translateY(-5px);
    }
}

.featureIcon {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
}

.blueIcon {
    background-color: $blue-light;
    color: $blue;
}

.orangeIcon {
    background-color: $orange-light;
    color: $orange;
}

.featureTitle {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: $gray-800;
}

.featureText {
    color: $gray-700;
    margin-bottom: 1.5rem;
}

.featureList {
    list-style: none;
    padding: 0;
}

.featureItem {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.75rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.checkIcon {
    color: $orange;
    margin-right: 0.75rem;
    margin-top: 0.25rem;
    flex-shrink: 0;
}

// Testimonials
.testimonialsGrid {
    display: grid;
    gap: 2rem;

    @include respond-to("sm") {
        grid-template-columns: repeat(2, 1fr);
    }

    @include respond-to("lg") {
        grid-template-columns: repeat(3, 1fr);
    }
}

.testimonialCard {
    background-color: $white;
    padding: 2rem;
    border-radius: $radius;
    box-shadow: $shadow-md;
}

.testimonialHeader {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}

.testimonialAvatar {
    width: 3rem;
    height: 3rem;
    background-color: $gray-200;
    border-radius: 9999px;
    margin-right: 1rem;
}

.testimonialAuthor {
    h4 {
        font-weight: 700;
        margin-bottom: 0.25rem;
    }

    p {
        color: $gray-600;
        font-size: 0.875rem;
    }
}

.testimonialText {
    color: $gray-700;
    font-style: italic;
}

// Devices
.devicesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;

    @include respond-to("md") {
        flex-direction: row;
        justify-content: center;
    }
}

.deviceItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include respond-to("md") {
        margin: 0 2rem;
    }
}

.deviceIcon {
    width: 5rem;
    height: 5rem;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    font-size: 2rem;
}

.deviceTitle {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: $gray-800;
}

.deviceText {
    color: $gray-600;
}

// CTA Section
.ctaSection {
    background-color: $blue;
    color: $white;
    padding: 5rem 0;
    text-align: center;
}

.ctaTitle {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;

    @include respond-to("md") {
        font-size: 2.5rem;
    }
}

.ctaText {
    font-size: 1.25rem;
    max-width: 700px;
    margin: 0 auto 2rem;
}

.ctaButtons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;

    @include respond-to("sm") {
        flex-direction: row;
    }
}

// Buttons
.btnWhite {
    background-color: $white;
    color: $blue;
    padding: 0.75rem 2rem;
    border-radius: $radius;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: $gray-100;
    }
}

.btnOrange {
    background-color: $orange;
    color: $white;
    padding: 0.75rem 2rem;
    border-radius: $radius;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: $orange-dark;
    }
}