@import '../../../styles/variables';
@import '../../../styles/mixins';

.authPage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $gray-50;
}

.authContainer {
  max-width: 28rem;
  margin: 2rem auto;
  padding: 0 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.authCard {
  background-color: $white;
  border-radius: $radius;
  box-shadow: $shadow-md;
  padding: 2rem;
}

.authHeader {
  text-align: center;
  margin-bottom: 2rem;
}

.authTitle {
  font-size: 1.5rem;
  font-weight: 700;
  color: $gray-800;
  margin-bottom: 0.5rem;
}

.authSubtitle {
  color: $gray-600;
}

.authTypeSelector {
  display: flex;
  margin-bottom: 1.5rem;
  border-radius: $radius;
  overflow: hidden;
  border: 1px solid $gray-300;
}

.authTypeBtn {
  flex: 1;
  padding: 0.75rem;
  text-align: center;
  background-color: $white;
  border: none;
  cursor: pointer;
  transition: $transition;

  &.active {
    background-color: $blue;
    color: $white;
  }

  &:not(.active):hover {
    background-color: $gray-100;
  }
}

.authForm {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.formGroup {
  margin-bottom: 0;

  label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: $gray-700;
    margin-bottom: 0.25rem;
  }
}

.formRow {
  display: grid;
  gap: 1rem;

  @include respond-to('sm') {
    grid-template-columns: repeat(2, 1fr);
  }
}

.formControl {
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid $gray-300;
  border-radius: $radius;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: $orange;
    box-shadow: 0 0 0 2px rgba(249, 115, 22, 0.2);
  }
}

.inputWithIcon {
  position: relative;
  display: flex;
  align-items: center;
}

.inputIcon {
  position: absolute;
  left: 0.75rem;
  color: $gray-500;
  width: 1rem;
  height: 1rem;
}

.inputWithIcon .formControl {
  padding-left: 2.5rem;
}

.passwordToggle {
  position: absolute;
  right: 0.75rem;
  top: 1.9rem;
  background: none;
  border: none;
  color: $gray-500;
  cursor: pointer;

  &:hover {
    color: $gray-700;
  }
}

.formCheckbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  input[type="checkbox"] {
    width: 1rem;
    height: 1rem;
    accent-color: $orange;
  }

  label {
    font-size: 0.875rem;
    color: $gray-700;

    a {
      color: $blue;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.btn {
  @include button;
}

.btnPrimary {
  @include buttonPrimary;
}

.btnLarge {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
}

.authDivider {
  display: flex;
  align-items: center;
  margin: 1.5rem 0;

  &::before,
  &::after {
    content: "";
    flex-grow: 1;
    height: 1px;
    background-color: $gray-200;
  }

  span {
    padding: 0 1rem;
    color: $gray-500;
    font-size: 0.875rem;
  }
}

.authSocialButtons {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  @include respond-to('sm') {
    flex-direction: row;
  }
}

.authSocialBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem;
  border-radius: $radius;
  border: 1px solid $gray-300;
  background-color: $white;
  color: $gray-700;
  font-weight: 500;
  cursor: pointer;
  transition: $transition;
  flex: 1;

  &:hover {
    background-color: $gray-50;
  }
}

.authFooter {
  text-align: center;
  margin-top: 1.5rem;
  color: $gray-600;

  a {
    color: $blue;
    text-decoration: none;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
}