@import "../../styles/variables.scss";

.loaderBody {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    .loader {
        font-size: 48px;
        font-weight: bold;
        letter-spacing: 2px;
        color: $blue-site-transparent;
        text-align: center;
    }
}