@import "../../styles/variables.scss";

.divSelect {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    .labelSelect {
        display: flex;
        align-items: center;
    }

    .select {
        max-width: 100%;
        padding: 0.5rem 1rem;
        border: 1px solid $gray-300;
        border-radius: $radius;
        font-size: 1rem;

        &:focus {
            outline: none;
            text-decoration: none;
            border-bottom: 1px solid #0091ff;
        }
    }
}