@import "../../../../../styles/variables.scss";

.infoContainer {
    background-color: $blue-site-transparent;
    border-radius: 10px;
    padding: 10px;
    margin: 20px 0;
    max-width: 69vw;

    h2 {
        margin-top: 10px;
    }

    .titleAction {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 30px;
    }

    .personnalData {
        display: flex;
        flex-direction: column;
        font-size: 17px;
        gap: 3px;

        .fullname {
            font-size: 20px;
            font-weight: bolder;
            margin-bottom: 5px;
        }
    }

}

.judicialCalendar {
    position: relative;
    height: 270px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    align-items: center;
    gap: 20px;
    padding: 5px;

    &::-webkit-scrollbar-thumb {
        display: none;

        :hover>& {
            display: initial;
        }
    }

    .judicialCalendarItem {
        display: flex;
        flex-direction: column;
        height: 100px;
        min-width: 150px;
        margin: 5px 0;
        padding: 20px;
        border-radius: 5px;
        position: relative;
        justify-items: center;
        background: white;

        .actionBtn {
            position: absolute;
            display: none;
            top: 0;
            right: 0;
            gap: 5px;

            &:hover>& {
                display: flex;
            }
        }

        &:hover {
            box-shadow: 5px 5px 10px $orange-site-light;
            height: 200px;
            min-width: 250px;
            background: #ffffff !important;
        }

        &.pastEvents {
            background: #efefef;

            &:hover {
                height: 200px;
                min-width: 250px;

                .judicialCalendarContent {
                    .judicialCalendarDescription {
                        display: initial;
                    }
                }
            }
        }

        &.nextEvent {
            height: 200px;
            min-width: 250px;
            box-shadow: 5px 5px 10px $orange-site-light;

            .judicialCalendarContent {
                .judicialCalendarDescription {
                    display: initial;
                }
            }
        }

        &.nextEvent,
        &.futureEvents {
            .actionBtn {
                position: absolute;
                display: none;
                top: 0;
                right: 0;
                gap: 5px;

                :hover>& {
                    display: flex;
                }
            }
        }

        .judicialCalendarContent {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .judicialCalendarTitle {
                font-weight: bold;
                font-size: 20px;
            }

            .judicialCalendarDate {
                font-weight: bold;
            }

            .judicialCalendarDescription {
                display: none;
            }
        }
    }
}