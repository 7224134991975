.containerCalendar {
    position: relative;

    .enrolled {

        .legends {
            display: flex;
            padding: 10px 0;
            gap: 10px;

            // .legendAllow {
            //     height: 20px;
            //     width: 20px;
            //     border-radius: 5px;
            //     border: 1px solid green;
            //     background-color: rgba(142, 223, 129, 0.5);
            // }

            .legendNow {
                height: 20px;
                width: 20px;
                border-radius: 5px;
                border: 1px solid #fee574;
                background-color: #fffae3;
            }

            .legendUnallow {
                height: 20px;
                width: 20px;
                border-radius: 5px;
                border: 1px solid red;
                background-color: #ff000080;
            }
        }
    }

    .notEnrolled {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        width: 100%;
        height: 100%;
        z-index: 5;
        background-color: rgba(130, 130, 130, 0.9);
        text-align: center;
        font-weight: bold;
        font-size: large;
        border-radius: 5px;

        p {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;

            span {
                width: 100%;
            }
        }
    }
}