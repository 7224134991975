@import "../../styles/variables.scss";

.container {
    .title {
        text-align: center;
    }

    .summary {
        text-align: center;
    }

    .body {
        max-width: 100%;
        padding: 70px 200px;

        .bodyInfo {
            display: flex;
            flex-direction: column;
            background-color: $blue-site-transparent;
            padding: 70px 50px;
            gap: 20px;
            border-radius: 10px;

            .familyInfo {
                display: flex;
                align-items: baseline;
            }

            .phone {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                &>div {
                    width: 49%;
                }
            }

            .btn {
                display: flex;
                justify-content: space-between;

                i {
                    margin-right: 10px;
                }
            }
        }

    }
}