@mixin respond-to($breakpoint) {
  @if $breakpoint =="sm" {
    @media (min-width: $breakpoint-sm) {
      @content;
    }
  }

  @else if $breakpoint =="md" {
    @media (min-width: $breakpoint-md) {
      @content;
    }
  }

  @else if $breakpoint =="lg" {
    @media (min-width: $breakpoint-lg) {
      @content;
    }
  }

  @else if $breakpoint =="xl" {
    @media (min-width: $breakpoint-xl) {
      @content;
    }
  }
}

@mixin button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-weight: 500;
  border-radius: $radius;
  cursor: pointer;
  transition: $transition;
  border: none;
  text-decoration: none;
  text-align: center;
}

@mixin buttonPrimary {
  @include button;
  background-color: $orange;
  color: $white;

  &:hover {
    background-color: $orange-dark;
  }
}

@mixin buttonOutline {
  @include button;
  background-color: transparent;
  border: 1px solid $blue;
  color: $blue;

  &:hover {
    background-color: $blue-light;
  }
}

@mixin buttonWhite {
  @include button;
  background-color: $white;
  color: $blue;

  &:hover {
    background-color: $gray-100;
  }
}

@mixin buttonOutlineWhite {
  @include button;
  background-color: transparent;
  border: 1px solid $white;
  color: $white;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}