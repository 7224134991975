.card {
    border-radius: 10px;
    background-color: white;
}

.shadow {
    box-shadow: 1px 1px 3px grey;
}

.border {
    border: 1px solid grey;
}