@import "../../../styles/variables.scss";

.container {
    display: flex;
    height: 100%;

    .body {
        padding: 0 0 0 25px;
        width: 100%;

        .message {
            background-color: $bluegray-site;
            padding: 50px;
            border-radius: 5px;
            color: #ffffff;

            .user {
                font-size: 12px;
            }

            .date {
                font-size: 10px;
                width: 100%;
                text-align: end;
            }

            .left {
                display: flex;
                margin: 5px;
                justify-content: start;

                .msg {
                    border-radius: 10px;
                    padding: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    background-color: $blue-site;
                }
            }

            .right {
                display: flex;
                margin: 5px;
                justify-content: end;

                .msg {
                    border-radius: 10px;
                    padding: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                    background-color: $orange;
                }
            }

        }
    }
}