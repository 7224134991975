@import "../../styles/variables.scss";

.timer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .timeLeft {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 200px;
        width: 200px;
        border-radius: 200px;
        border: 10px solid $blue-site;
        background: $orange;
        color: #ffffff;
        font-size: 40px;
        font-weight: bold;

        .title {
            font-size: 10px;
            width: 100%;
            text-align: center;
        }

        .time {
            width: 100%;
            text-align: center;
        }
    }

    .actionTimer {
        display: flex;
        justify-content: space-between;
        width: 200px;
        margin-top: 10px;

    }
}

.mini {
    position: fixed;
    top: 60px;
    right: 10px;

    .timeLeft {
        height: 80px;
        width: 80px;
        border-radius: 60px;
        font-size: 15px;
        border: 5px solid $blue-site;

        .title {
            display: none;
        }
    }

    .actionTimer {
        display: none;

    }

    &:hover {
        .timeLeft {
            height: 200px;
            width: 200px;
            border-radius: 200px;
            font-size: 40px;
            border: 10px solid $blue-site;
            background-color: #ffffff;
            padding: 10px;
            border: 2px solid $blue-site;

            .title {
                display: initial;
            }
        }

        .actionTimer {
            display: flex;
        }

    }
}