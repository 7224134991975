@import "../../../../../styles/variables.scss";

.container {
    height: 100%;

    .dropdown {
        position: absolute;
        left: 100%;
        top: 0;
        background: white;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 5px 5px 5px #607d8b1c;
        width: 100%;

        .btnClose {
            position: absolute;
            right: 0;
            top: 0;
        }

        .title {
            font-size: 25px;
        }

        .body {
            margin-top: 15px;
            width: 100%;
        }

        .footer {
            width: 100%;
            display: flex;
            margin-top: 25px;
            justify-content: end;
            gap: 20px;
        }
    }

    .elementsContainer {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 200px;
        height: 100%;

        button {
            width: 100%;
        }
    }
}

.modalBody {
    display: flex;
    flex-direction: column;
    gap: 20px;
}