@import "../../styles/variables.scss";

.btn {
    display: flex;
    align-items: center;
    text-decoration: none;
    border-radius: 5px;
    border: none;
    text-align: center;
    font-weight: bold;
    padding: 5px 10px;
    font-size: 15px;
    color: inherit;
    background-color: inherit;
    cursor: pointer;
}

.primary {
    color: #ffffff;
    background-color: $orange;
}

.secondary {
    color: #ffffff;
    background-color: #101213;
}