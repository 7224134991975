.elementContainer {

    padding: 20px;
    position: relative;

    &:hover {
        background-color: #607d8b1c;

        .removeElement {
            display: initial;
            position: absolute;
            right: 0;
            top: 0;
            color: red;
        }
    }

    .removeElement {
        display: none;
    }
}