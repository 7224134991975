@import "../../../styles/variables.scss";

.container {
    display: flex;
    flex-wrap: wrap;

    .header {
        display: flex;
        width: 100%;
        height: 100px;
        padding: 0 50px;
        background: linear-gradient(to right, $blue-site, $orange);
        color: #ffffff;
        align-content: center;

        .title {
            margin: auto 0;
        }
    }

    .body {
        width: 100%;
        padding: 10px 0;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
}

.containerFav {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: calc(100% - 75px);

    .fav {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 50%;

        i {
            font-size: 70px;
        }

        small {
            font-size: x-small;
        }

        .favName {
            width: 100%;
            text-align: center;
            font-size: 15px;
        }
    }
}