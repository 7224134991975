@import "../../../../styles/variables.scss";

.container {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;

    .iconCol {
        width: 50px;
    }

    .tableContainer {
        width: 100%;
    }

    .hiden {
        display: none;

    }

    .caseContainer {
        width: 100%;
    }
}