.searchBar {
        position: absolute;
        top: 390px;
        z-index: 6;
        background-color: white;
        padding: 50px;
        display: flex;
        justify-content: center;
        gap: 10px;
        border-radius: 5px;
        box-shadow: 5px 5px 10px grey;

        @media (max-width: 1000px) {
                flex-wrap: wrap;
                margin: 0 50px;
        }

        .searchInput {
                width: 220px;
        }
}