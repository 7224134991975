@import "../../../styles/variables.scss";

.widget {
    height: calc(calc(100vw - 405px) / 4);
    width: calc(calc(100vw - 405px) / 4);
    padding: 10px;

    @media screen and (max-width: 1200px) {
        height: calc(100vw - 20px);
        width: calc(100vw - 20px);
    }

    h2 {
        text-align: center;
    }

    ul {
        width: 100%;
        padding: 0 5%;
        list-style-type: none;

        button {
            text-align: start;
            align-items: start;
        }
    }

    .widgetMsg {
        display: flex;
        color: $blue-site;
        gap: 10px;
    }

    .containerFav {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: calc(100% - 75px);
        justify-content: center;

        .fav {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 50%;

            i {
                font-size: 70px;
            }

            small {
                font-size: x-small;
            }

            .favName {
                width: 100%;
                text-align: center;
                font-size: 15px;
            }
        }
    }
}