@import "../../../styles/variables.scss";

.container {
    display: flex;

    .body {
        padding: 0 25px;
        width: 100%;
    }
}

.navBar {
    display: flex;
    height: 70px;
    width: 100%;
    background-color: $blue-site-transparent;
    border-radius: 5px;
    margin-bottom: 10px;

    &:hover {
        box-shadow: 5px 5px 10px grey;
    }

    .btn {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:hover .label {
            text-decoration: underline 2px $orange;
        }

        .icon {
            font-size: 30px;
        }

        .label {
            font-size: 10px;
        }
    }

    .action {
        font-size: 30px;
    }
}