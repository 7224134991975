@import "../../../../../styles/variables.scss";

.container {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;

    .iconCol {
        width: 50px;
    }

    .tableContainer {
        width: 100%;
    }

    .hiden {
        display: none;

    }

    .caseContainer {
        width: 100%;
    }
}

.infoContainer {
    background-color: $blue-site-transparent;
    border-radius: 10px;
    padding: 10px;
    margin: 20px 0;

    h2 {
        margin-top: 10px;
    }

    .titleAction {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 30px;
    }

    .personnalData {
        display: flex;
        flex-direction: column;
        font-size: 17px;
        gap: 3px;

        .fullname {
            font-size: 20px;
            font-weight: bolder;
            margin-bottom: 5px;
        }
    }

}