.prestation {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 25px;
    width: 70vw;
    max-width: 500px;

    .header {
        text-align: center;
        font-size: 20px;
    }

    .body {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;

        div {
            width: 100%;
        }

        span {
            width: 100%;
            font-size: 17px;
        }
    }

    .notConnected {
        width: 100%;
        font-weight: bold;
        text-align: center;
        margin-bottom: 50px;
    }

    .footer {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: end;
    }
}