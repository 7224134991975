@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.lawyers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .map {
        width: 100vw;
    }

    .searchBarContainer {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 50px 150px;

        @media (max-width:$breakpoint-lg) {
            padding: 80px 150px;
        }

        @media (max-width:$breakpoint-md) {
            padding: 30px 150px;
        }

        @media (max-width:$breakpoint-sm) {
            padding: 150px 150px;
        }
    }

    .listContainer {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        margin-bottom: 100px;
    }
}