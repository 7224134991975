@import "../../../../styles/variables.scss";

.cover {
    position: relative;
    width: 100%;
    height: 350px;
    background: linear-gradient(to right, $blue-site, $orange);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 5px 5px 10px grey;


    @media (max-width:$breakpoint-md) {
        height: 150px;
    }

    .photoContainer {
        display: flex;
        position: absolute;
        top: 250px;
        left: 8vw;
        align-items: end;

        @media (max-width:$breakpoint-md) {
            top: 92px;
            left: 3vw;
        }

        .photoCard {
            height: 150px;
            width: 150px;
            border-radius: 100%;
            background-color: $blue-site;
            border: 2px solid $orange;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            color: transparent;
            z-index: 1;

            @media (max-width:$breakpoint-md) {
                height: 100px;
                width: 100px;
            }
        }

        .bodyId {
            display: flex;
            flex-direction: column;
            justify-items: end;
            gap: 2px;
            margin-bottom: 25px;

            .name {
                // font-size: calc(2.5vw + 1.5vh);
                font-size: clamp(14px, 4vw, 40px);
                white-space: nowrap;
                font-weight: bold;
                background: linear-gradient(to right, #ffffffab 90%, #ffffff00);
                padding: 8px 20px 8px 40px;
                margin-left: -25px;

                @media (min-width:$breakpoint-lg) {
                    padding: 8px 20px 8px 70px;
                    margin-left: -60px;
                }

            }

            .lawfirm {
                position: relative;
                max-width: fit-content;
                border-bottom: 3px solid $orange;

                a {
                    font-size: clamp(8px, 2vw, 15px);
                    white-space: nowrap;
                }
            }
        }


    }


}