.labelDay {
    margin-bottom: 10px;
    font-size: 20px;
}

.days {

    input {
        font-family: inherit;

        &:disabled {
            background-color: #efefef;
        }
    }

}