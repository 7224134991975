.pricingSimulator {
    width: fit-content;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px;
    text-align: center;
}

.simulatorControls {
    display: flex;
    gap: 10px;
}
 .gain {
        font-size: 30px;
            width: 100%;
 }
.containerTotalCost{
    display: flex;
    gap: 50px;
   
.totalCost {
        font-size: 24px;
        color: #1e52a4;
    }
}
