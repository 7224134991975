@import "../../styles/variables.scss";

.chip {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 5px;
    font-weight: bold;
    font-size: inherit;
    padding: 7.5px 15px;
    color: white;
    background-color: $blue-site;
    border-radius: 20px;

    span {
        width: 100%;
    }

    button {
        padding: 0
    }

    ;
}