@import "../../../styles/variables.scss";

.container {
    display: flex;
    flex-direction: column;
    width: 400px;
    border: 1px solid $gray-300;
    border-radius: 15px;
    padding: 15px;
    gap: 20px;
    color: $gray-500;

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .icon {
            font-size: 30px;
            height: 40px;
            width: 40px;
            background: $gray-300;
            padding: 10px;
            border-radius: 100%;
            color: white;
        }

        .title {
            font-size: 25px;
            font-weight: bold;
        }

        .content {
            text-align: center;
        }
    }

    .footer {
        text-align: center;
    }
}