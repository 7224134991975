.container {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.optionContainer {
    display: flex;
    width: 100%;
    margin-top: 10px;
}

.inputContainer {
    display: flex;
    align-items: center;
    margin: 0 15px 0 0;
}

.input {
    height: 25px;
    width: 25px;
    margin: 0 5px 0 0;
}