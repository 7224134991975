@import "../../../../../../styles/variables.scss";

.noteTab {
    min-width: 20%;
    border-right: 2px solid $orange;

    button {
        width: 100%;
        border-radius: 0;
    }

    input {
        color: white;
    }
}