@import "../../styles/variables.scss";

.toolbar {
    display: flex;
    height: 70px;
    background-color: $blue-site;
    border-radius: 5px;
    margin-bottom: 10px;

    &:hover {
        box-shadow: 5px 5px 10px grey;
    }

    .btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #ffffff;

        &:hover .label {
            text-decoration: underline 2px $orange;
        }

        .icon {
            font-size: 30px;
        }

        .label {
            font-size: 10px;
        }
    }

    .action {
        font-size: 30px;
    }

    .start {
        display: flex;
        width: 100%;
        justify-content: start;
    }

    .center {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .end {
        display: flex;
        width: 100%;
        justify-content: end;
    }

}

.sticky {
    position: sticky;
    top: 0;
    z-index: 4;
    box-shadow: 5px 5px 10px grey;
}