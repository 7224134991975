.label {
    font-size: 20px;
    font-weight: bold;
}

.identity {
    display: flex;
    gap: 10px;
    align-items: end;
    margin-bottom: 10px;
}

.identityCivility {
    width: 25%;
}

.identityName {
    width: 100%;
}

.identityFirstname {
    width: 100%;
}