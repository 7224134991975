@import "../../../../styles/variables.scss";

.body {
    max-width: 100%;
    margin-top: 20px;

    .userInfoContainer {
        display: flex;
        background-color: $blue-site-transparent;
        border-radius: 8px;
        overflow: hidden;
    }

    .userProfile {
        text-align: center;
        padding: 30px;
        color: $blue-site;
    }

    .profilePicture {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        object-fit: cover;
        border: 5px solid $orange;
    }

    .userName {
        font-size: 18px;
    }

    .userEmail {
        font-size: 18px;
        margin-bottom: 5px;
    }

    .userDetails {
        padding: 20px 30px;
        width: 100%;

        h3 {
            margin-bottom: 20px;
            color: $blue-site;
        }

        table {
            border-spacing: 20px 10px;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                margin-bottom: 10px;
                display: flex;

                strong {
                    width: 30%;
                    display: inline-block;
                }
            }
        }

    }
}